.tool-table {
    border-collapse: collapse;
    border: 1px solid #FF0000;
    width: 100%;
}
.tool-table th {
    color: white;
    font-weight: normal;
    text-align: center;
}
.tool-list-name-th {
    width: 20%;
}
.tool-list-image-th {
    width: 20%;
}
.tool-table td {
    text-align: left;
}

.tool-category-th {
    width: 20%;
    max-width: 100px;
}
.tool-list-image {
    width: 100%;
}
/*@media only screen and (max-width: 480px) {
    img {
        width: 100%;
    }
}*/