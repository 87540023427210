/* https://codeconvey.com/responsive-menu-css-only/ */

.app-navbar {
    display: flex;
    position: relative;
    background-color: var(--brand-color);
    font-size: 1em;
    color: white;
    width: var(--app-inner-width);
    height: var(--app-nav-height);
    width: 100%;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    overflow: unset;
    z-index: 5;
}

.app-navbar-logo {
    background-color: white;
    white-space: normal;
    height: var(--app-nav-height);
    clear: none;
    float: left;
}

    .app-navbar-logo a {
        display: flex;
    }

    .app-navbar-logo img {
        pointer-events: none;
        height: var(--app-nav-height);
        clear: none;
        float: left;
    }

.app-menu {
    box-sizing: border-box;
    display: flex;
    margin: 0px;
    padding: 0 5px 0 5px;
    white-space: nowrap;
    height: 100%;
}

.app-navbar .app-menu .app-menu-item:hover {
    box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.3);
}

.app-navbar .app-menu .app-menu-item a {
    text-decoration: none;
}


/* navbar hamburger icon */
.app-navbar .hamburger-label {
    cursor: pointer;
    display: inline-block;
    padding: 15px 20px;
    position: relative;
    user-select: none;
    float: left;
}

    .app-navbar .hamburger-label .hamburger-icon {
        background: #fff;
        display: block;
        height: 2px;
        position: relative;
        transition: background .2s ease-out;
        width: 18px;
    }

        .app-navbar .hamburger-label .hamburger-icon:before,
        .app-navbar .hamburger-label .hamburger-icon:after {
            background: #fff;
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            transition: all .2s ease-out;
            width: 100%;
        }

        .app-navbar .hamburger-label .hamburger-icon:before {
            top: 5px;
        }

        .app-navbar .hamburger-label .hamburger-icon:after {
            top: -5px;
        }
/* navbar hamburger button */
.app-navbar .hamburger-button {
    display: none;
}

    .app-navbar .hamburger-button:checked ~ .hamburger-label .hamburger-icon {
        background: transparent;
    }

        .app-navbar .hamburger-button:checked ~ .hamburger-label .hamburger-icon:before {
            transform: rotate(-45deg);
        }

        .app-navbar .hamburger-button:checked ~ .hamburger-label .hamburger-icon:after {
            transform: rotate(45deg);
        }

    .app-navbar .hamburger-button:checked ~ .hamburger-label:not(.steps) .hamburger-icon:before,
    .app-navbar .hamburger-button:checked ~ .hamburger-label:not(.steps) .hamburger-icon:after {
        top: 0;
    }

.app-menu-header-label {
    padding: 5px;
}

.app-menu-header-close {
    padding: 5px;
    margin-left: auto;
}

/* Cascading Menus */
.cascading-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    cursor: pointer;
    width: 100%;
}

    .cascading-menu:hover {
        box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.3);
    }

.cascading-menu-label {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    height: 100%;
    /*width: 100%;*/
    padding: 0px;
    vertical-align: middle;
}

.cascading-menu-symbol {
    display: flex;
    justify-content: center;
    align-items: center;
}

.cascading-sub-menu {
    display: none;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: left;
    background-color: white;
    color: var(--brand-color);
    border: 1px solid gray;
    border-collapse: collapse;
    min-width: 100px;
    padding: 10px 0px 10px 0px;
    z-index: 10;
}

.cascading-menu-column {
    flex-direction: column;
    border-collapse: collapse;
    width: 15rem;
}

    .cascading-menu-column:hover {
        cursor: default;
    }

    .cascading-menu-column h5 {
        color: var(--brand-color);
        margin: 0px;
        padding: 0px;
        font-size: 1.1em;
        font-weight: 600;
        text-align: left;
        padding: 5px;
    }

.cascading-menu-column-border {
    border-left: 1px solid gray;
}

.cascading-menu-item {
    box-sizing: border-box;
    color: var(--app-menu-item-color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0px 0px 0px 0px;
    padding: 5px 10px 0 10px;
    font-weight: 600;
    vertical-align: middle;
    min-height: 2em;
    width: 100%;
}

    .cascading-menu-item:hover {
        box-shadow: inset 0 0 0 10em rgba(255, 255, 255, 0.3);
    }

    .cascading-menu-item a:hover {
        text-decoration: underline;
    }

.cascading-menu-item-description {
    box-sizing: border-box;
    font-weight: normal;
    font-size: .95rem;
    text-align: left;
    padding: 0px 0px 5px 0px;
    white-space: normal;
    overflow-wrap: normal;
}


/* Responsive: larger format (i.e. larger tables, desktop) */
@media only screen and (min-width: 768px) {
    .app-navbar .logo {
        line-height: 1;
    }

    .app-navbar .hamburger-label {
        display: none;
    }

    .app-navbar .app-menu-header {
        display: none;
    }

    .app-navbar .app-menu {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: left;
    }

    .app-menu-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 0px 0px 0px 0px;
        padding: 0 10px 0 10px;
        height: 100%;
        vertical-align: middle;
    }

        .app-menu-item a {
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            padding: 0px 0px;
            margin: 0px auto;
            /*border-right: 1px solid rgba(255, 255, 255, 0.2);*/
            height: 100%;
            vertical-align: middle;
        }

    .cascading-menu {
        min-height: var(--app-nav-height);
    }

    .cascading-menu-label {
        height: var(--app-nav-height);
        padding: 0px;
    }

    .cascading-menu-caption {
        padding-left: 15px;
    }

    .cascading-menu-symbol {
        transform: rotate(90deg) scaleY(1.25) scaleX(1.25);
        width: 1.5em;
    }

    .cascading-sub-menu-container {
        position: relative;
        width: 50px;
        overflow: visible;
    }
    
    .cascading-sub-menu {
        position: absolute;
    }
    
    .cascading-menu-item {
        box-sizing: border-box;
        padding: 0 10px 0 10px;
        width: 100%;
    }

        .cascading-menu-item a {
            color: var(--app-menu-item-color);
        }

/*    .cascading-menu:hover .cascading-sub-menu {
        display: flex;
    }
*/
}
/* Responsive: small format (i.e. phones) */
@media only screen and (max-width: 768px) {
    .app-navbar {
        width: 100%;
    }

        .app-navbar .hamburger-label {
            display: block;
        }

    .app-menu-header {
        background-color: var(--brand-color);
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 2px solid white;
        height: 2em;
        width: 100%;
    }

    .app-menu {
        background-color: white;
        display: none;
        flex-direction: column;
        align-items: flex-start;
        justify-content: left;
        position: absolute;
        padding: 0px;
        top: 0px;
        left: 0px;
        min-height: 100vh;
        min-width: 75vw;
        /*height: var(--app-mobile-menu-height);*/
    }

    .app-menu-item {
        background-color: white;
        color: var(--app-menu-item-color);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        border-bottom: 1px solid gray;
        border-collapse: collapse;
        /*border-bottom: 1px solid white;*/
        height: 2em;
        width: 100%;
    }

        .app-menu-item a {
            color: var(--app-menu-item-color);
            padding: 10px 15px 10px 15px;
        }


    .cascading-menu-label {
        color: var(--app-menu-item-color);
        justify-content: flex-start;
        align-items: center;
        font-weight: bold;
        border-bottom: 1px solid gray;
        border-collapse: collapse;
        width: 100%;
    }

    .cascading-sub-menu-container {
        width: 100%;
    }

    .cascading-sub-menu {
        width: 100%;
        box-sizing: border-box;
        flex-direction: column;
        padding: 0px 0px 0px 15px;
        border: 0px;
        width: 100%;
    }

    .cascading-menu-caption {
        padding: 5px 15px 5px 15px;
    }

    .cascading-menu:hover .cascading-sub-menu {
        display: flex;
    }

    .cascading-menu-column-border {
        border-left: none;
    }

    .cascading-menu-symbol {
        margin-top: auto;
        margin-bottom: auto;
        margin-left: auto;
        padding-right: 10px;
        transform: rotate(90deg) scaleY(1.25) scaleX(1.25);
        width: 1.5em;
    }

    .cascading-menu-item a {
        color: var(--app-menu-item-color);
        padding: 5px 15px 5px 25px;
    }
}
