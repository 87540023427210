.board-feet-pad {
}
.board-feet-expression {
    display: flex;
    flex-direction: row;
    flex-shrink: initial;
}
.board-feet-expression-numerator {
    border-bottom: 1px solid black;
}
.board-feet-expression-denominator {
}
.board-feet-expression-left-side {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.board-feet-expression-right-side {
}
.board-feet-results-row {
    display: flex;
    flex-direction: row;
}
.board-feet-results-label-column {
    display: flex;
    flex-direction: column;
}
.board-feet-results-value-column {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
.board-feet-label {
    font-weight: bold;
}
.board-feet-highlight {
    background-color: var(--highlight-color);
    font-weight: bold;
    width: 100%;
}
