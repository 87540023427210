.app-header {
    background-color: var(--brand-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: var(--app-inner-width);
    margin-left: auto;
    margin-right: auto;
}

/* Responsive: larger format (i.e. larger tables, desktop) */
@media only screen and (min-width: 768px) {
    .app-header {
    }
}
/* Responsive: small format (i.e. phones) */
@media only screen and (max-width: 768px) {
    .app-header {
    }
}