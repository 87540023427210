.home-header {
    display: flex;
    flex-direction: row;
}
.home-logo {
    height: 15vmin;
    pointer-events: none;
    margin-right: 10px;
}
.home-power-tools-image {
    width: 100%;
    pointer-events: none;
/*    margin-right: 10px;
*/}
.home-calculator {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: auto;
}
.home-bottom-contents {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: auto;
}
