.calculator {
    display: block;
    border: 4px solid lightgray;
    border-radius: 6px;
    padding: 2px 2px 2px 2px;
    min-width: 18rem;
    max-width: 600px;
    font-size: 1.25rem;
    overflow: unset;
    position: relative;
}

.calculator-titlebar {
    display: flex;
    flex-direction: row;
}

.calculator-title {
    flex-grow: 1;
    text-align: center;
    font-size: 1.25em;
}

.calculator-body {
    display: block;
    margin-top: 2px;
}
.calculator-body-left {
    display: inline-block;
    width: 50%;
    vertical-align: top;
}
.calculator-body-right {
    display: inline-block;
    width: 50%;
    vertical-align: top;
}
.calculator-expression {
    display: flex;
    flex-direction: column;
    height: auto;
    background-color: #c5c5c5;
    border: 2px solid lightgray;
    border-radius: 4px;
    text-align: right;
    font-size: 1.00em;
}

.calculator-results {
    display: flex;
    flex-direction: column;
    height: auto;
    border: 2px solid lightgray;
    border-radius: 4px;
    background-color: var(--brand-color);
    text-align: right;
    font-size: 1.75em;
    margin-bottom: 5px;
}

/*.calculator-operators {
    display: flex;
}

    .calculator-operators button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0.5px solid #868686;
        background-color: var(--brand-color);
        aspect-ratio: 1 / 1;
        width: 16%;
        transition: all 0.4s ease-in-out;
        cursor: pointer;
        font-size: 1.15em;
    }
        .calculator-operators button:hover {
            background-color: #525252;
        }

    .calculator-operators img {
        aspect-ratio: 1 / 1;
        width: 100%;
    }
*/
.calculator-buttons {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    width: auto;
}

.calculator-number-pad {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 100%;
}

.calculator-number-pad-row {
    display: flex;
    flex-direction: row;
}
    .calculator-number-pad-row button {
        aspect-ratio: 1 / 1;
        width: 25%;
        background-color: #b9b9b9;
        cursor: pointer;
        transition: all 0.4s ease-in-out;
        border: 0.5px solid #b0b0b0;
        font-size: 1.25em;
    }
        .calculator-number-pad-row button:hover {
            background-color: #c9c9c9;
        }

.calculator-double-button {
    aspect-ratio: 2 / 0.975 !important;
    width: 60% !important;
}
.calculator-operator-button {
    background-color: #FEBD17 !important;
}
.calculator-hidden-button {
    background-color: white !important;
    border: 0px solid #b0b0b0 !important;
}

        
.calculator-enter-pad {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    padding: 2px 10px 2px 2px;
}

    .calculator-enter-pad button {
        background-color: #b9b9b9;
        cursor: pointer;
        transition: all 0.4s ease-in-out;
        border: 0.5px solid #b0b0b0;
        font-size: 1.25em;
    }

.calculator-equal-key {
    aspect-ratio: 1 / 5.75;
    width: 100%;
    /*    height: 100%;
*/
}
.calculator-input-field {
    
}
.calculator-results-field {
}
    .calculator-results-field input {
        background-color: var(--highlight-color);
        border-width: 1px;
        font-weight: bold;
    }
