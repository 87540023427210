.error-page {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: var(--max-body-width);
    width: 100%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}
