.tabs-horizontal {
    display: flex !important;
    flex-direction: row !important;
    margin-bottom: 10px;
    width: min-content;
}

.tabs-vertical {
    display: flex !important;
    flex-direction: column !important;
    margin-right: 10px;
    height: 300px;
    width: min-content;
}

.tabs-active-tab-container {
    border-right: 1px solid lightgray;
    border-collapse: collapse;
    display: flex !important;
    flex-direction: column !important;
    font-size: 1.0em;
}
    .tabs-active-tab-container button {
        border-left: 6px solid var(--brand-color);
        border-radius: 0px;
        box-shadow: none;
        font-size: 1.0em;
        margin: 0px;
    }
        .tabs-active-tab-container button:hover {
            /*border-bottom: 3px solid var(--brand-color);*/
            background-color: lightgray;
            opacity: 1;
        }

.tabs-inactive-tab-container {
    border-right: 1px solid lightgray;
    border-collapse: collapse;
    display: flex !important;
    flex-direction: column !important;
    font-size: 1.0em;
}
    .tabs-inactive-tab-container button {
        border-left: 5px solid white;
        border-radius: 0px;
        box-shadow: none;
        font-size: 1.0em;
        margin: 0px;
    }
        .tabs-inactive-tab-container button:hover {
            /*border-bottom: 3px solid var(--brand-color);*/
            background-color: lightgray;
            opacity: 1;
        }

.tabs-active-tab {
    background-color: white;
    /*border: 1px solid var(--brand-color);*/
    border-radius: 0px;
    color: black;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    width: 100%;
}

.tabs-inactive-tab {
    background-color: white;
    /*order-radius: 0px;*/
    color: black;
    font-weight: normal;
    text-align: left;
    white-space: nowrap;
    width: 100%;
}
