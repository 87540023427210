.app-body {
    background-color: white;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    font-size: 1.0rem;
    color: black;
    padding: 0px;
    width: var(--app-inner-width);
    margin-left: auto;
    margin-right: auto;
    overflow: auto;
}

.app-page {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1 1 auto;
    /*padding: 10px 10px 10px 10px;*/
    width: 100%;
    height: 100%;
}

.app-section {
    background-color: var(--app-section-background-color);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.app-grow-section {
    background-color: var(--app-section-background-color);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    width: 100%
}

.app-section-inner {
    background-color: var(--app-section-inner-background-color);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: var(--app-inner-width);
    padding: 5px 10px 5px 10px;
    margin-left: auto;
    margin-right: auto;
}

.app-grow-section-inner {
    background-color: var(--app-section-inner-background-color);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex: auto;
    width: var(--app-inner-width);
    padding: 5px 10px 5px 10px;
    margin-left: auto;
    margin-right: auto;
}

.app-section-overlay {
    background-color: transparent;
    color: white;
    justify-content: center;
    height: 100%;
}


.app-fullheight {
    height: 100%;
}

.app-page-banner {
}

.app-page-summary {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.app-quotes {
    display: flex;
    flex-direction: row;
    text-align: center;
}

    .app-quotes p {
        box-sizing: border-box;
        padding: 0px 15px 0px 15px;
        font-size: 1.25em;
        text-align: left;
    }

.app-quote {
    box-sizing: border-box;
    color: var(--brand-color);
    font-size: 2.0rem;
    font-weight: 600;
    padding: 30px 15px 0px 15px;
    width: 100%;
}

.app-copyright {
    color: var( --footer-color);
}

    .app-copyright a {
        color: var( --footer-color);
        text-decoration: none;
    }

        .app-copyright a:link {
            color: white;
            text-decoration: none;
        }
