title {
    display: block;
    color: var(--brand-color);
    margin-block-start: 0.10em;
    margin-block-end: 0.10em;
    font-size: 2.00em;
    font-weight: bold;
}

h1 {
    color: var(--brand-color);
    margin-block-start: 0.10em;
    margin-block-end: 0.10em;
}

h2 {
    color: var(--brand-color);
}

h3 {
    color: var(--brand-color);
}

a {
    text-align: left !important;
}

    a:link {
        text-decoration: none;
    }

button {
    background-color: var(--brand-color);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px;
    margin: 5px;
    font-size: .85em;
    cursor: pointer;
}
