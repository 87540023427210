.close-button {
    display: block !important;
    height: 1.0em;
    width: 1.0em;
    background: none;
    border-radius: 5px;
    position: relative;
}
    .close-button:hover {
        background: lightgray;
    }

    .close-button:after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        content: "\274c";
        font-size: 0.50em;
        color: #fff;
        line-height: 2.00em;
        text-align: center;
    }
    