.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 1.5rem;
    height: 1.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 2px;
    padding-right: 5px;
    /*    z-index: 10;
*/
}

    .hamburger span {
        background-color: gray;
        border: 1px solid gray;
        width: 1.5rem;
        height: 0.10rem;
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;
    }

.hamburger-menu-closed {
    display: block;
    height: 0px;
    visibility: hidden;
    position: relative;
    z-index: 10;
}

.hamburger-menu-open {
    background-color: white;
    display: block;
    width: 70%;
    height: 100%;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
    visibility: visible;
    position: absolute;
    z-index: 10;
}

.hamburger-menu-items {
    background-color: white;
    display: block;
    padding: 5px 2px 2px 5px
}

.hamburger-menu-item {
}
    .hamburger-menu-item div {
    }
        .hamburger-menu-item div:hover {
            cursor: pointer;
            text-decoration: underline;
        }

    .hamburger-menu-item a {
        color: black;
        /*    color: var(--brand-color);*/
    }

        .hamburger-menu-item a:link {
            text-decoration: none;
        }

        .hamburger-menu-item a:visited {
            text-decoration: none;
        }

        .hamburger-menu-item a:hover {
            text-decoration: underline;
        }
