.app-footer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background-color: var(--brand-color);
    width: var(--app-inner-width);
    min-height: 30px;
    box-sizing: border-box;
    white-space: nowrap;
    font-size: .9em;
    color: white;
    margin-left: auto;
    margin-right: auto;
}

    .app-footer div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        white-space: normal;
        vertical-align: middle;
        padding-left: 10px;
        padding-right: 10px
    }
    .app-footer a {
        color: var( --footer-color);
        text-decoration: none;
    }

        .app-footer a:link {
            color: white;
            text-decoration: none;
        }
