.cookie-consent {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    position: fixed;
    top: calc(100vh * 0.75);
    background-color: white;
    border: 2px solid var(--consent-color);
    border-radius: 8px;
    width: calc(var(--app-inner-width) * 0.90);
    margin-left: auto;
    margin-right: auto;
    color: black;
    font-size: .9rem;
    z-index: 1;
}

.cookie-consent-body {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cookie-consent-text {
    box-sizing: border-box;
    padding: 10px 0px 10px 15px;
}
    .cookie-consent-text p {
        margin-top: 0px;
        margin-bottom: 10px;
    }

.cookie-consent-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-left: auto;
    margin-right: auto;
}

.cookie-consent button {
    background-color: var(--consent-color);
    color: white;
}

.persistent-cookie {
    height: 5rem;
    pointer-events: none;
}
