caption {
    color: var(--brand-color);
    font-size: 1em;
    font-weight: bold;
}

table {
    border-collapse: collapse;
    border: 1px solid var(--brand-color);
}

th {
    color: white;
    font-weight: normal;
    max-width: 100px;
    text-align: center;
}

td {
    border: 1px solid var(--brand-color);
    text-align: left;
}

thead {
    background-color: var(--brand-color);
}

    thead tr {
        color: black;
    }

        thead tr th {
            background-color: var(--brand-color);
            color: black;
            border: 1px solid black;
        }
