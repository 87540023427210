
:root {
    --brand-color: #FEBD17;
    --footer-color: white;
    --consent-color: #0D54B1;
    --highlight-color: #FEBD17;
    --app-nav-height: 36px;
    --app-mobile-width-breakpoint: 768px;
    --app-mobile-menu-height: calc(100vh - var(--app-nav-height));
    --app-menu-item-color: #005da2;
    --app-background-color: lightsteelblue;
    --app-section-background-color: lightsteelblue;
    --app-section-inner-background-color: white;
}
@media screen and (min-width: 1200px) {
    :root {
        --app-inner-width: 1200px;
    }
}

@media screen and (max-width: 1200px) {
    :root {
        --app-inner-width: 100vh;
    }
}

.llc {
    color: var(--brand-color);
}
