.app {
    /*    background-image: url('./images/wave-background.png');*/
    background-color: var(--app-background-color);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    min-width: 100vw;
    min-height: 100vh;
}
/*.app-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: var(--app-inner-width);
    min-height: 100vh;
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
}
*/
/*.app-full-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}
.app-full-width-image {
    width: 100vw;
}
*/

.app-link {
    color: var(--brand-color);
}
/*
@media only screen and (min-width: 768px) {
    .app-container {
        min-width: var(--app-inner-width);
    }
}
*/