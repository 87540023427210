.terms-of-use-page {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: initial;
}

.terms-of-use-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: var(--brand-color);
    color: black;
    height: 100px;
    width: 100%;
}

.terms-of-use-header-title {
    background-color: var(--brand-color);
    color: black;
    text-align: center;
    height: 100%;
    width: 60%;
}


.terms-of-use-body {
    font-size: 0.90em;
    color: black;
    text-align: left;
    width: 100%;
    overflow: auto;
}
.terms-of-use-h1 {
    color: white;
}
