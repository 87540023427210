.privacy-page {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: initial;
}
.privacy-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--brand-color);
    color: black;
    height: 125px;
    width: 100%;
}
.privacy-header-title {
    background-color: var(--brand-color);
    color: black;
    text-align: center;
    height: 100%;
    width: 60%;
}
.privacy-header-settings {
    background-color: var(--brand-color);
    color: black;
    height: 100%;
    width: 40%;
    text-align: right;
    vertical-align: bottom;
}
.privacy-body {
    font-size: 0.90em;
    color: black;
    text-align: left;
    width: 100%;
    overflow: auto;
}
.privacy-h1 {
    color: white;
    margin-block-end: 0;
}
privacy-cookie-notice {
    width: 100%;
}

.privacy-cookie-table {
    width: 100%;
    vertical-align: top;
}
.privacy-cookie-thead {
    background-color: var(--brand-color);
    width: 100%;
}
.privacy-cookie-type-th {
    color: white;
    font-weight: normal;
    width: 20%;
    text-align: center;
}
.privacy-cookie-example-th {
    color: white;
    font-weight: normal;
    width: 80%;
    text-align: center;
}
.privacy-cookie-type-td {
    font-weight: bold;
}
li {
    margin-block-start: 0;
    margin-block-end: 5px;
}
