.privacy-settings-content {
}

.privacy-settings-tabs {
    box-sizing: border-box;
    display: flex !important;
    flex-direction: row !important;
    align-content: flex-start;
    justify-content: flex-start;
    width: 100%;
    font-size: .9rem;
}

.private-settings-tab-content {
    text-align: justify;
    flex: 1;
    overflow: auto;
    max-height: 600px;
}

.private-settings-tab-content-header {
    display: flex;
    flex-direction: row;
}

    .private-settings-tab-content-header h2 {
        margin-block-start: 0px;
        padding-top: 0px;
    }
.privacy-read-only-toogle {
}

@media only screen and (min-width: 768px) {
    .private-settings-tab-content {
        width: 500px;
    }
}
@media only screen and (max-width: 768px) {
    .private-settings-tab-content {
        width: 100%;
        max-height: 400px;
    }
}
