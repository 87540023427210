.lumber-dimension-nominal-th {
    color: white;
    font-weight: normal;
    width: 20%;
    max-width: 100px;
    text-align: center;
}

.lumber-dimension-actual-th {
    color: white;
    font-weight: normal;
    width: 20%;
    max-width: 100px;
    text-align: center;
}
.lumber-calculator {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: auto;
}
